import React, { useContext } from "react";
import Header from "../../Components/Header/Header";
import PressCard from "../../Components/PressCard/PressCard";
import * as styles from "./Press.module.css";
import Footer from "../../Components/Footer/Footer";
import { graphql, Link } from "gatsby";
// import ButtonKit from "../../Components/ButtonKit/ButtonKit";
import { SEO } from "../../Components/Seo/seo";
// import { download } from "../../util/viniProductPage.helper";
import { languageContext } from "../../Components/Header/Header";

const Press = ({ data }) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data.allStrapiPress.edges[0]?.node?.localizations || {};

  const {
    // DownloadButtonLink,
    PageTitle

    // PressKitDescription, 
  } = language === "it"
      ? localizations?.data[0]?.attributes : data?.allStrapiPress?.edges[0]?.node || {};
  const { edges } = data?.allStrapiArticle || {};

  // const { PressKitPDF } = data.allStrapiScaricaPressKit.edges[0].node || {};
  return (
    <>
      <div className="pageWrapper">
        <div className={styles.pressMainWrap}>
          <Header />
          <div className={styles.pressSection}>
            <div className={styles.headingName}>{PageTitle}</div>
            <div className={styles.pressMain}>
              {/* <VisitContent press={true} /> */}
              <div className={styles.pressGrid}>
                {edges?.map((item) => (
                  <Link to={`/PressSingola/${item?.node?.slug}`}>
                    <PressCard
                      img={
                        language === "it"
                          ? item?.node?.localizations?.data[0]?.attributes
                            ?.Article[0]?.Image?.data?.attributes?.url
                          : item?.node?.Article[0]?.Image?.url
                      }
                      pressDescription={
                        language === "it"
                          ? item?.node?.localizations?.data[0]?.attributes
                            ?.Article[0]?.Title
                          : item?.node?.Article[0]?.Title
                      }
                      pressHeading={
                        language === "it"
                          ? item?.node?.localizations?.data[0]?.attributes
                            ?.Article[0]?.ParaGraph1
                          : item?.node?.Article[0]?.ParaGraph1
                      }
                    />
                  </Link>
                ))}
              </div>
            </div>

            {/* <ButtonKit
          description={PressKitDescription}
          button={language === "it" ? 'Scarica press kit':'Download press kit'}
          downloadPdf={PressKitPDF?.url}
        /> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Press;

export const query = graphql`
  query Press {
    allStrapiPress {
      edges {
        node {
          DownloadButtonLink {
            href
            label
          }
          PageTitle
          PressKitDescription
          MetaKeyword
          MetaDescription
          localizations {
            data {
              attributes {
                PageTitle
                PressKitDescription
                MetaKeyword
                MetaDescription
              }
            }
          }
        }
      }
    }
    allStrapiArticle {
      edges {
        node {
          Article {
            Image {
              url
            }
            ParaGraph1
            ParaGraph2
            ParaGraph3
            Title
          }
          slug
          localizations {
            data {
              attributes {
                Article {
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  ParaGraph1
                  ParaGraph2
                  ParaGraph3
                  Title
                }
                slug
              }
            }
          }
        }
      }
    }
  }
  `;

export const Head = ({ data }) => {
  const localizations = data?.allStrapiPress?.edges[0]?.node?.localizations || {};
  const { MetaKeyword, MetaDescription } = localizations?.data[0]?.attributes
  return <SEO title={MetaKeyword} description={MetaDescription} />;
};